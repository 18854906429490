import React from 'react';
import { Link, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/layout';
import CallToAction from '../components/CallToAction';


const LeistungenPage = ({ data, pageContext }) => {
  
  const services = data.allMdx.nodes;

  return (
    <Layout title="Leistungen" heroTitle="Die richtige Lösung" heroSubtitle="Für Ihren Bedarf" imageData={data.heroImage.childImageSharp.gatsbyImageData} pageContext={pageContext}>
      <section className="section">
        <div className="columns">
          <div className="column">
        
            <div className="block" >
              <div className="container">
                <h1 className="title">Unsere Leistungen</h1>
                
                <p>Aufgrund unserer partnerschaftlichen Struktur können wir mit dedizierter Fachexpertise ein breites Leistungsspektrum bedienen. Für einen Einstieg besuchen Sie unser <Link to="/angebot" className="link">Angebotsportfolio</Link>. Die vollständige Liste aller Leistungen finden Sie nachstehend.</p>
                <div className="columns is-multiline is-">
                {
                  services.map((service) => {
                    const backgroundImageStack = [
                      `radial-gradient(at 5% top, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.9))`
                    ];
                    const imageData = service.frontmatter.hero_image.childImageSharp.gatsbyImageData;
                    if (imageData) backgroundImageStack.push(imageData);
                    
                    return (
                      <div className="column is-full">
                        <div className="box">
                          <div className="subtitle mb-2">
                            {service.frontmatter.title}
                          </div>
                          <div className="content mb-2">
                            {service.frontmatter.description}
                          </div>
                          {
                            service.excerpt && <Link to={`/${service.slug}`} className="link offer-button">
                              Mehr erfahren...
                            </Link>
                          }
                        </div>
                      </div>
                    );
                  })
                }
                </div>
                
                
              </div>
            </div>
            
          </div>
        </div>
      </section>
      
      <CallToAction title="Was können wir für Sie tun?" subtitle="Erfahren Sie mehr über uns und unsere Leistungen." />
      
    </Layout>
  );
}

export const query = graphql`query LeistungenPage {
  heroImage: file(relativePath: {eq: "angebot.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  allMdx(
    filter: {frontmatter: {templateKey: {eq: "leistung-page"}}}
    sort: {fields: frontmatter___order}
  ) {
    nodes {
      frontmatter {
        title
        description
        hero_image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        hero_image_alt
      }
      body
      slug
      excerpt(pruneLength: 100)
    }
  }
}
`

export default LeistungenPage;
